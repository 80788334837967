import { initializeApp,firebase } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence  } from "firebase/firestore";
import { getStorage } from "firebase/storage"; 
import 'firebase/firestore';
import 'firebase/storage';



const firebaseConfig = {
  apiKey: "AIzaSyAY0YQL6O6T7F_z6K6cqr0pcKSFl0ez9s8",
  authDomain: "communetransportpublic.firebaseapp.com",
  projectId: "communetransportpublic",
  storageBucket: "communetransportpublic.appspot.com",
  messagingSenderId: "337023930752",
  appId: "1:337023930752:web:2be26e4559b003353ab165",
  measurementId: "G-ZZWK6YPNSX"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app); 
var db = getFirestore(app);
const storage = getStorage(app); 

// Activer la persistance Firestore (hors ligne)
enableIndexedDbPersistence(db).then(() => {
  // Persistance activée avec succès
})
.catch((err) => {
  console.error('Erreur lors de l\'activation de la persistance Firestore:', err);
});



export { auth, db, storage };
export default app;