import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const dateAujourdhui = new Date();
const nomsMois = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
const jour = dateAujourdhui.getDate();
const mois = nomsMois[dateAujourdhui.getMonth()];
const annee = dateAujourdhui.getFullYear();
const dateFormatees = `${jour} ${mois}, ${annee}`;

export default new Vuex.Store({
	state:{
		item:{},
		tabVehiculesPapier: [],
	    curentList: [],
	    papers: [],
		communeData:[],
		dateFormatee: dateFormatees,
	},
	mutations:{
		setItem(state, item) {
	        state.item = item;
	    },
		setCommuneData(state, communeData) {
	        state.communeData = communeData;
	    },
		setDateFormatee(state, dateFormatee) {
	        state.dateFormatee = dateFormatee;
	    },
	    setTabVehiculesPapier(state, tabVehiculesPapier) {
	        state.tabVehiculesPapier = tabVehiculesPapier;
	    },
	    setCurentList(state, curentList) {
	        state.curentList = curentList;
	    },
	    setPapers(state, papers) {
	        state.papers = papers;
	    },

	    clearStates(state){
	    	state.item = {};
	        state.tabVehiculesPapier = [];
	        state.curentList = [];
	        state.papers = [];
	        state.communeData = [];
	    },

		clearStatescommuneData(state){
	        state.communeData = [];
	    }
	},
	actions:{

	},
	getters:{
	    getItem: state => state.item,
	    getDateFormatee: state => state.dateFormatee,
	    getTabVehiculesPapier: state => state.tabVehiculesPapier,
	    getCurentList: state => state.curentList,
	    getPapers: state => state.papers,
	    getCommuneData: state => state.communeData,
	}

})