<template>
    <v-app>
        <!-- <App /> -->
        <App v-if="connected" />
        <Login v-if="connected == false" />
        <div v-if="connected">
            <div v-if="isLoading" class="loader">
                <div class="preloader-img pere-text">
                    <img src="./assets/img/loading.png" alt="">
                </div>
            </div>
        </div> 
    </v-app>
</template>

<script>
import { getAuth, onAuthStateChanged } from "firebase/auth";
import App from "./App.vue"
import Login from "@/views/Users/Login.vue"
import Cookies from 'js-cookie';
import { db } from '@/firebase/init';
import { doc, getDoc, query, collection, where, getDocs } from "firebase/firestore";

export default {
    components: {
        App,
        Login,
    },
    data() {
        return {
            connected: false,
            isLoading: true,
        }
    },
    async created() {
        // this.connected = true

        const maasoo = Cookies.get('maasooID');
        if (maasoo) {
            const cleanedID = maasoo.replace(/"/g, '');
            const userRef = doc(collection(db, 'users'), cleanedID);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                this.connected = true;
            } else {
                this.connected = false;
            }
        }
        console.log("AUTH", this.connected)
    },
    mounted() {
        setTimeout(() => {
            this.isLoading = false;
        }, 4000);
    },
}
</script>
<style scoped>
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    transition: opacity 1s, visibility 1s;
    z-index: 999999;
}

.loader-hidden {
    opacity: 0;
    visibility: hidden;
}

.loader::after {
    content: "";
    width: 100px;
    height: 100px;
    border: 5px solid #e3e3e3;
    border-top-color: var(--color-principale);
    background-color: #fff;
    border-radius: 50%;
    animation: loading 1s ease infinite;
}

@keyframes loading {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

.loader .preloader-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}

.loader .preloader-img img {
    max-width: 55px;
}
</style>