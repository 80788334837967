import Vue from 'vue'
import App from './App.vue'
import AuthLayout from './AuthLayout.vue'
import Login from './views/Users/Login.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import '@mdi/font/css/materialdesignicons.css'
import { db } from '@/firebase/init';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Cookies from 'js-cookie';
import axios from 'axios'
import store from './store';
import $ from "jquery";
window.$ = $;
window.jQuery = $;


// Vue.use(VueQrcodeReader);

Vue.config.productionTip = false;

if (process.env.NODE_ENV == 'development') {
  Vue.BASE_URL = Vue.prototype.BASE_URL = '';
} else { // Production
  // Vue.BASE_URL = Vue.prototype.BASE_URL = '';
  Vue.BASE_URL = Vue.prototype.BASE_URL = '/projet_dna/commune-maasoo';
}

new Vue({
  vuetify,
  router,
  store,
  render: h => {
    return h(AuthLayout);
  }
}).$mount('#app');

// main.js



// const auth = getAuth();
// onAuthStateChanged(auth, (user) => {
//   new Vue({
//     vuetify,
//     router,
//     render: h => {
//       if (user) {
//         return h(App);
//       } else {
//         return h(Login);
//       }
//     }
//   }).$mount('#app');
// });