<template>
  <v-app>
    <Navbar  v-if="shouldShowNavbar" ></Navbar>
    <!-- <v-main class=""   style="background-image: url('../assets/img/pdc.jpeg'); background-size: cover; background-position: center;heigth:100%"> -->
    <v-main class="">
      <router-view></router-view>
    </v-main>
    <!-- <Footer/> -->
  </v-app>
</template>

<script>
import Navbar from './components/Navbar'
export default {
  name: 'App',
  components: {
    Navbar,
    // Footer
  },
  data() {
    return {
      shouldShowNavbar: true,
    };
  },
  watch: {

    '$route'(to) {
      console.log("to:", to)
      this.shouldShowNavbar = to.path !== '/login';
      console.log("to:", this.shouldShowNavbar)

    },
  },
};
</script>

<style scoped> 
 .theme--light.v-application {
    background: #f9fafb !important;
    color: rgba(0, 0, 0, 0.87);
}
</style>
