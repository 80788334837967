<template>
<v-container fluid class="bg_image_login">
    <div class="login">
        <v-row align="center" justify="center">
            <v-col cols="12" md="5" class="auth_fluid_left">
                <img src="../../assets/img/Maasoo33.png" alt="image" height="250" class="pr-16">
            </v-col>
            <v-col cols="12" md="7" style="border: 1px black;">
                <div class="auth_fluid_right" >
                    <v-card class="py-8 px-8 sm:px-8" style="border-radius: 8px">
                        <div class="text-center mb-5 d-lg-none">
                            <img src="../../assets/img/Maasoo33.png" alt="Image" height="80" />
                        </div>
                        <v-form @submit.prevent="login">
                            <h2 class="text-center">Authentifiez-vous</h2>
                            <h6 class="text-center  grey--text ">Connectez-vous à votre compte afin de pouvoir continuer à créer <br>et éditer vos parcours d'intégration.</h6>
                            <v-row align="center" justify="center">
                                <v-col cols="12" sm="12">
                                    <div class="input-div one">
                                        <div class="i">
                                            <i class="fas fa-user"></i>
                                        </div>
                                        <div class="div">
                                            <v-text-field v-model="mail" name="mail" class="mt-16" label="Email" color="blue" autocomplete="false" placeholder="Entrer votre identifiant" append-icon="mdi-account" :rules="rules.textRequis" clearable outlined></v-text-field>
                                        </div>
                                    </div>

                                    <div class="input-div pass">
                                        <div class="i">
                                            <i class="fas fa-lock"></i>
                                        </div>
                                        <div class="div">
                                            <v-text-field v-model="password" name="password" color="blue" autocomplete="false" label="Mot de passe" :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showpassword ? 'text' : 'password'" required placeholder="Entrer votre mots de passe" @click:append="showpassword = !showpassword" :rules="rules.textRequis" clearable outlined></v-text-field>
                                        </div>
                                    </div>
                                    <v-row>
                                        <v-col cols="12" sm="7">
                                            <v-checkbox v-model="rememberMe" label="Souvenir de moi" class="mt-n1" color="#a11523">
                                            </v-checkbox>
                                        </v-col>
                                    </v-row>
                                    <v-btn type="submit" color="#a11523" class="py-6 my-5" dark block tile>Se connecter</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                    </v-card>
                </div>
            </v-col>
        </v-row>
    </div>

    <!-- - snackbar -->
    <v-snackbar v-model="snackBar.status" top :color="snackBar.color">
            {{ snackBar.message }}

        <template v-slot:action="{ attrs }">
            <v-btn color="white" text v-bind="attrs" @click="snackBar.status = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </template>
    </v-snackbar>

</v-container>
</template>

<script>
import {
    db
} from '@/firebase/init';
import {
    doc,
    getDoc,
    query,
    collection,
    where,
    getDocs
} from "firebase/firestore";
import Cookies from 'js-cookie';
import Vue from 'vue';

export default {
    data() {
        return {
            mail: '',
            password: '',
            showpassword: false,
            snackbar: false,
            rememberMe: false,
            ColorNotif: "",
            colorText: "",
            iconText: "",
            iconColor: "",
            text: ``,
            rules: {
                textRequis: [
                    v => !!v || 'Champs réquis',
                ],
            },
            snackBar: {
                message: "",
                color: "grey darken-4",
                status: false
            },
        };
    },
    async created() {
        const maasoo = Cookies.get('maasoo');
        if (maasoo) {
            const cleanedID = maasoo.replace(/"/g, '');
            const userRef = doc(collection(db, 'users'), cleanedID);
            const userSnapshot = await getDoc(userRef);

            console.log("Utilisateur trouvé :", maasoo);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                console.log("Utilisateur trouvé :", userData);
                console.log("ID du document de l'utilisateur :", maasoo);

                this.mail = userData.mail;
                this.password = userData.password;
                this.rememberMe = true;
            } else {
                console.log("Aucun utilisateur trouvé.");
            }
        }

    },
    methods: {
        async login() {
            var that = this;
            try {
                if ((this.password === null) || (this.password === "") || (this.password === undefined)) {
                    return;
                }

                const userQuery = query(collection(db, 'users'), where('user_mail', '==', this.mail), where('user_password', '==', this.password));
                const userSnapshot = await getDocs(userQuery);
                // console.log("Utilisateur userSnapshot :", this.password);

                if (!userSnapshot.empty) {
                    const userData = userSnapshot.docs[0].data();
                    const documentId = userSnapshot.docs[0].id;
                    console.log("Utilisateur trouvé :", userData);
                    // console.log("Utilisateur 1 :", userData.user_role);
                    console.log("ID du document de l'utilisateur :", documentId);

                    Cookies.set('maasooID', JSON.stringify(documentId));
                    Cookies.set('maasooRole', userData.user_role);

                    if (this.rememberMe) {
                        Cookies.set('maasoo', JSON.stringify(documentId), {
                            expires: 7
                        });
                    }

                    // that.ColorNotif = ('#97741a')
                    // that.snackbar = true;
                    // that.text = "Authentification réussi";
                    // that.colorText = ('white')
                    // that.iconText = "mdi-check-circle"
                    // that.iconColor = 'success'

                    this.snackBar = {
                        message: 'Authentification réussi',
                        color: 'green darken-2',
                        status: true
                    };

                    // setTimeout(() => {
                    //   if (this.$route.path !== '/') {
                    //     this.$router.push('/');
                    //   } else {
                    //     console.log("dans la même route Lesy eh");
                    //   }
                    // }, 5000);

                    if (this.$route.path !== '/') {
                        this.$router.push('/');
                        // window.location.href = window.location.href;
                    } else {
                        console.log("dans la même route Lesy eh");
                    }

                    location.reload();

                } else {
                    // that.ColorNotif = ('red')
                    // that.snackbar = true;
                    // that.text = "Mail ou Mots de pass incorrect";
                    // that.colorText = ('white')
                    // that.iconText = "mdi-alert"
                    // that.iconColor = 'warning'

                    this.snackBar = {
                        message: 'Authentification non réussi',
                        color: 'red darken-2',
                        status: true
                    };

                    console.error("Aucun utilisateur trouvé 123.");
                }

                // that.ColorNotif = ('#97741a')
                // that.snackbar = true;
                // that.text = "Authentification réussi";
                // that.colorText = ('white')
                // that.iconText = "mdi-check-circle"
                // that.iconColor = 'success'

                // setTimeout(() => {
                //   if (this.$route.path !== '/') {
                //     this.$router.push('/');
                //   } else {
                //     console.log("dans la même route Lesy eh");
                //   }
                // }, 5000);

            } catch (error) {
                that.ColorNotif = ('red')
                that.snackbar = true;
                that.text = error.message;
                that.colorText = ('white')
                that.iconText = "mdi-alert"
                that.iconColor = 'warning'
                console.error(error.message);
            }

        },
        BackToLogin() {
            this.$router.push(`/`);
        },
        BackToRegister() {
            this.$router.push(`/register`);
        },
    }
};
</script>

<style scoped>

.container {
    background-image: url('/src/assets/img/1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}
.auth_fluid_right {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.v-sheet.v-card {
    width: 500px;
}

.auth_fluid_left {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/src/assets/img/design_login.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
}

@media (max-width: 991.98px) {
    .auth_fluid_left {
        display: none;
    }
}
</style>
