<template>
  <nav>
    <!-- drawer = !drawer" -->
    <v-app-bar color="#fff" density="compact" dark app>
      <v-app-bar-nav-icon @click.stop="isMobile ? (drawer = !drawer) : (rail = !rail)"
        color="barnavIcon"></v-app-bar-nav-icon>
      <v-toolbar-title class="text-uppercase">
        <!-- <span style="font-size: medium;">pharmacie</span> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <div v-if="connecter">
          <!-- <span @click="compte" style="cursor:pointer">Utilisateur: {{ nom }}</span> -->

          <v-icon color="#a11523" size="x-large" right @click="dialogConfirm = true">mdi-power</v-icon>

        </div>
        <div v-else>
          <v-btn text @click="logout">
            <v-icon color="#a11523" left>mdi-account-circle</v-icon>Se connecter
          </v-btn>
        </div>
      </div>

      <!-- debut dialog -->
      <v-dialog v-model="dialogConfirm" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h6" :color="drawerColor">
            Confirmation
          </v-card-title>
          <v-card-text>Voulez-vous vraiment vous déconnecter?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="connexion">
              Se déconnecter
            </v-btn>
            <v-btn color="dark darken-1" text @click="dialogConfirm = false">
              Annuler
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- end dialog -->
    </v-app-bar>
    <!-- absolute  temporary -->
    <!-- permanent -->
    <v-navigation-drawer height="100%" v-model="drawer" :rail="rail" :absolute="isMobile ? true : false"
      :temporary="isMobile ? true : false" :permanent="isMobile ? false : true" @click="rail = false" dark app
      :color="drawerColor" :width="rail ? '100' : '260'" class="custom-scroll-container">
      <div class="text-center mt-1" style="display: flex; justify-content: center; align-items: center;">
        <v-list-item-avatar style="width: 70px; height: 70px; border-radius: 0;">
          <v-img src="/maasoo.png"></v-img>
        </v-list-item-avatar>
      </div>

      <v-list flat class="mt-5">
        <v-list-item-group color="#a11523">

          <v-list-item v-for="(item, index) in items" :key="index" :to="item.route" active-class="border"
            class="ml-2 my-3" :ripple="false" :title="item.label">
            <b></b>
            <b></b>
            <v-list-item-icon style="text-align: center;">
              <v-icon :title="item.label" right v-text="item.icon"></v-icon>
            </v-list-item-icon>


            <v-list-item-content style="padding-left: 30px;">
              <v-list-item-title :class="getTextClasses(index)">{{ item.label }}</v-list-item-title>
            </v-list-item-content>

          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <!-- <v-navigation-drawer v-model="drawer" dark app color="red" class="no-scrollbar">
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="/maasoo.png"></v-img>
        </v-list-item-avatar>


        <v-list-item-content>
          <v-list-item-title>{{ nom }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>

        <v-list-item v-for="(item, index) in items" :key="item.key" :to="item.route" class="py-3"
          :style="getListItemStyle(index)" style="background-color: transparent !important;" @click="activateLink(index)">
          <v-list-item-icon>
            <v-icon :color="getIconColor(index)">{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title :class="getTextClasses(index)">{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer> -->




  </nav>
</template>

<script>
import Cookies from 'js-cookie';
import { db } from '@/firebase/init';
import { doc, collection, getDoc, updateDoc, addDoc, deleteDoc, arrayUnion } from 'firebase/firestore';
import Vue from 'vue'
export default {
  data: () => ({
    dataGlobal: '',
    connecter: null,
    drawer: true,
    activeLink: 0,
    linkPermissions: {},
    dialogConfirm: false,
    rail: true,
    isMobile: false,
    drawerColor: '#001c47',
    barnavIcon: '#001c47',
    isShow: true,
    // links: [
    //   { icon: 'mdi-view-dashboard', text: 'Dashboard', route: '/', is_true: true },
    //   // { icon: 'mdi-plus-circle-outline', text: 'New Product', route: '/new' },
    //   { icon: 'mdi-file', text: 'Fiche médicale individuelle', route: '/ficheIndividuel', is_true: false },
    //   { icon: 'mdi-tooth', text: 'Fiche soin dentaire', route: '/ficheSoinDentaire', is_true: false },
    //   // { icon: 'mdi-link-variant', text: 'Vente Associer', route: '/association', is_true: false },
    //   { icon: 'mdi-account-multiple', text: 'Liste Utilisateur', route: '/listeUsers', is_true: false },
    //   { icon: 'mdi-cog', text: 'Parmètres', route: '/lieuPage', is_true: false },
    //   { icon: 'mdi-information', text: 'A propos', route: '/about', is_true: true },
    //   // { icon: 'mdi-account-cog', text: 'Compte', route: '/compte', is_true: true }
    // ],
    itemsAll: [
      {
        key: '1',
        icon: 'mdi-view-dashboard',
        label: 'Dashboard',
        title: 'Dashboard',
        route: '/', is_true: false
      },
      {
        key: '2',
        icon: 'mdi-map-marker-multiple',
        label: 'Localisation',
        title: 'localisation',
        route: '/localisation', is_true: false
      },
      // {
      //   key: '3',
      //   icon: 'mdi-printer',
      //   label: 'Demande impression badge',
      //   title: 'demande_impression_badge',
      //   route: '/demande_impression_badge', is_true: false
      // }, 
      {
        key: '4',
        icon: 'mdi-account',
        label: 'Proprietaires',
        title: 'Proprietaires',
        route: '/proprietaires', is_true: false
      },
      {
        key: '5',
        label: 'Chauffeurs',
        title: 'Chauffeurs',
        icon: 'mdi-account-group',
        route: '/chauffeurs', is_true: false
      },
      // {
      //   key: 'sub1',
      //   icon: 'mdi-car', 
      //   label: 'Véhicules',
      //   title: 'Véhicules',
      //   children: [
      {
        key: '6',
        label: 'Types de véhicule',
        title: 'Types de véhicule',
        icon: 'mdi-file-document',
        route: '/documents&typevehicules', is_true: false
      },
      {
        key: '7',
        label: 'Véhicule',
        title: 'Véhicule',
        icon: 'mdi-car',
        route: '/vehicule', is_true: false
      },

      {
        key: '8',
        icon: 'mdi-file-link',
        label: 'Documents',
        title: 'Documents',
        route: '/documents', is_true: false
      },
      {
        key: '9',
        icon: 'mdi-book-account',
        label: 'Comptes',
        title: 'Comptes',
        route: '/compte', is_true: false
      },
      // {
      //   key: '10',
      //   icon: 'mdi-account-multiple',
      //   label: 'Liste des Utilisateurs',
      //   title: 'Liste des Utilisateurs',
      //   route: '/listeUsers', is_true: false
      // },
      {
        key: '12',
        icon: 'mdi-information',
        label: 'A propos',
        title: 'A propos',
        route: '/about', is_true: false
      },
    ],

    itemsAdmin: [
      {
        key: '10',
        icon: 'mdi-city',
        label: 'Liste des Communes',
        title: 'Liste des Communes',
        route: '/listeCommunes', is_true: false
      },
      {
        key: '11',
        icon: 'mdi-account-multiple',
        label: 'Liste des Utilisateurs',
        title: 'Liste des Utilisateurs',
        route: '/listeUsers', is_true: false
      },
    ],

    itemsPrinter: [
      {
        key: '1',
        icon: 'mdi-view-dashboard',
        label: 'Dashboard',
        title: 'Dashboard',
        route: '/', is_true: false
      },
      {
        key: '3',
        icon: 'mdi-printer',
        label: 'Demande impression badge',
        title: 'demande_impression_badge',
        route: '/demande_impression_badge', is_true: false
      },
      {
        key: '9',
        icon: 'mdi-book-account',
        label: 'Comptes',
        title: 'Comptes',
        route: '/compte', is_true: false
      },
      {
        key: '12',
        icon: 'mdi-information',
        label: 'A propos',
        title: 'A propos',
        route: '/about', is_true: false
      },
    ],
    items: [],
    nom: '',
  }),

  created() {
    // this.isMobile = window.innerWidth <= 600;
    // this.getUser();
    // this.drawer = this.shouldShowDrawer();
  },

  computed: {
    shouldShowDrawers() {

      const currentRoute = this.$router.currentRoute;
      return currentRoute.path !== '/';
    },
  },

  watch: {
    '$vuetify.breakpoint.width'(newWidth) {
      this.isMobile = newWidth <= 600;
    },
  },

  // watch: {

  //   '$route'() {
  //   this.isMobile = window.innerWidth <= 600;

  //     // Lorsque la route change, mettez à jour la condition
  //     this.drawer = this.shouldShowDrawer();
  //   },
  // },
  async mounted() {
    this.detectMobile(); // Appel initial
    window.addEventListener('resize', this.detectMobile);
    const maasooID = Cookies.get('maasooID');
    if (maasooID) {
      const cleanedID = maasooID.replace(/"/g, '');
      const userRef = doc(collection(db, 'users'), cleanedID);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.data();
        if (userData) {
          console.log('userData', userData)
          this.connecter = true;
          this.nom = userData.user_name;
          this.isShow = userData.user_role == "Administrateur" ? false : true
          if (userData.user_role == "Administrateur") {
            // this.items = this.itemsAll.concat(this.itemsAdmin);

            //--------------------
            const lastIndex = this.itemsAll.length - 2;
            this.itemsAll.splice(lastIndex, 0, ...this.itemsAdmin);
            this.items = this.itemsAll;
            //--------------------
            console.log('this.items',this.items);
          }
          else if (userData.user_role == "Imprimerie") {
            this.items = this.itemsPrinter
          }
          else {
            this.items = [];
          }
        } else {
          this.connecter = false;
        }
      }
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.detectMobile);
  },

  methods: {

    detectMobile() {
      this.isMobile = window.innerWidth <= 600;
    },

    activateLink(index) {
      this.activeLink = index;
    },
    isActiveLink(index) {
      return this.activeLink === index;
    },
    getListItemStyle(index) {
      return {
        backgroundColor: this.isActiveLink(index) ? '' : 'transparent',
        borderTopLeftRadius: this.isActiveLink(index) ? '0' : 'rgb(35, 112, 44)',
        borderBottomLeftRadius: this.isActiveLink(index) ? '0' : 'rgb(35, 112, 44)'
      };
    },
    getIconColor(index) {
      return this.isActiveLink(index) ? '#a11523' : 'white';
    },
    getTextClasses(index) {
      const isActive = this.$route.path === index;
      return {
        'text-red': isActive,
        'text-white': !isActive

        // 'color-green': this.isActiveLink(index),
        // 'color-white': !this.isActiveLink(index)
      };
    },
    compte() {
      if (this.$route.path !== `/compte`) {
        this.$router.push(`/compte`);
      }
    },
    logout() {
      Cookies.remove('maasooID');
      Cookies.remove('maasoo');
      // const auth = getAuth();
      // signOut(auth).then(() => {
      //   console.log("Utilisateur déconnecté");
      // }).catch((error) => {
      //   console.log(error.message);
      // });
      this.$router.push(`/login`);
      this.dialogConfirm = false;
    },
    connexion() {
      Cookies.remove('maasooID');
      this.dialogConfirm = false;
      this.$router.push(`/login`);
    },
    ListeUsers() {
      this.$router.push(`/compte`);
    },
    async hasUserRole(role) {

      // Récupérer les données utilisateur depuis Firestore en utilisant l'ID d'authentification
      const userDocRef = doc(db, 'users', getCookies("maasooID"));
      const userDocSnapshot = await getDoc(userDocRef);
      const userData = userDocSnapshot.data();
      this.dataGlobal = userDocSnapshot.data();

      // Vérifier si les données utilisateur existent et si le rôle correspond
      console.log(userData, role, userData && userData.role === role)
      return userData && userData.role === role;

      // return false;
    },
    async shouldShowLink(link) {
      if (!link.role) {
        return true;
      } else if (link.role === 'admin') {
        const isAdmin = await this.hasUserRole('admin');
        return isAdmin;
      } else {

        return false;
      }
    },
  }
};
</script>

<style scoped>
nav .no-scrollbar {
  overflow: hidden !important;
  font-size: 5px !important;
}

.border {
  border-left: 0 solid rgb(35, 112, 44);
  border-right: 0 solid rgb(35, 112, 44);
}

.color-green {
  color: #a11523;
}

.theme--dark.v-btn {
  color: #011C47;
}

.color-white {
  color: white;
}

.mdi:before,
.mdi-set {
  display: inline-block;
  font: normal normal normal 24px/1 "Material Design Icons";
  /* font-size: inherit; */
  text-rendering: auto;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.v-list-item__title {
  align-self: center;
  font-size: 15px;
}

element.style {
  border-top-left-radius: 0em;
  border-bottom-left-radius: 0em;
}

.border {
  background: #f9fafb;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  text-decoration: none;
  position: relative;


}

.border b:nth-child(1),
.border b:nth-child(2) {
  display: none;
  position: absolute;
  width: calc(100% - 16px);
  height: 23px;
  background: #f9fafb;
}

.border b:nth-child(1) {
  top: -22px;
}

.border b:nth-child(2) {
  bottom: -22px;
}

.border b:nth-child(1)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  background: #011C47;

}

.border b:nth-child(2)::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 30px;
  background: #011C47;

}

.border b:nth-child(1),
.border b:nth-child(2) {
  display: block;
}

.v-application--is-ltr .v-list-item__avatar:first-child {
  margin-right: 0 !important;
}

.v-sheet.v-app-bar.v-toolbar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08);
}
</style>
