import Vue from 'vue'
import VueRouter from 'vue-router'
// import { createRouter, createWebHashHistory, VueRouter } from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: () => import('../views/Home/HomeView.vue')
  },
  
  {
    path: '/compte',
    name: 'Compte',
    component: () => import('../views/Users/Compte.vue')
  },
  
  {
    path: '/listeUsers',
    name: 'ListeUsers',
    component: () => import('../views/Users/ListeUsers.vue')  
  },
  {
    path: '/listeCommunes',
    name: 'listeCommunes',
    component: () => import('../views/Commune/Commune.vue')  
  },
  // {
  //   path: '/premiere_licence',
  //   name: 'premiere_licence',
  //   component: () => import('../views/pdf/premiere_licence.vue') 
  // },

  // {
  //   path: '/deuxieme_licence',
  //   name: 'deuxieme_licence',
  //   component: () => import('../views/pdf/deuxieme_licence.vue') 
  // },
  // {
  //   path: '/visite_technique',
  //   name: 'visite_technique',
  //   component: () => import('../views/pdf/visite_technique.vue')
  // },
  // {
  //   path: '/cahier_de_charge_des_exigences_particuliers',
  //   name: 'cahier_de_charge_des_exigences_particuliers',
  //   component: () => import('../views/pdf/cahier_de_charge_des_exigences_particuliers.vue')
  // },
  // {
  //   path: '/cahier_de_charge_generale',
  //   name: 'cahier_de_charge_generale',
  //   component: () => import('../views/pdf/cahier_de_charge_generale.vue')
  // },
  // {
  //   path: '/attestation_de_conformite',
  //   name: 'attestation_de_conformite',
  //   component: () => import('../views/pdf/attestation_de_conformite.vue')
  // },

  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Users/Login.vue')
  },
  {
    path: '/testLogin',
    name: 'testLogin',
    component: () => import('../views/Users/testLogin.vue')
  },
  {
    path: '/proprietaires',
    name: 'Proprietaires',
    component: () => import('../views/Proprietaires/Proprietaires.vue')
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/Documents/Documents.vue') 
  },
  {
    path: '/documents&typevehicules',
    name: 'DocumentsTypeVehicules',
    component: () => import('../views/DocumentsTypeVehicules/DocumentsTypeVehicules.vue') 
  },
  {
    path: '/chauffeurs',
    name: 'Chauffeurs',
    component: () => import('../views/Chauffeurs/Chauffeurs.vue')
  },
  {
    path: '/attributionvehicules',
    name: 'AttributionVehicules',
    component: () => import('../views/AttributionVehicules/AttributionVehicules.vue') 
  },
  {
    path: '/demande_impression_badge',
    name: 'demande_impression_badge',
    component: () => import('../views/demande_impression_badge/demande_impression_badge.vue')
  },
  {
    path: '/vehicule',
    name: 'Vehicule',
    component: () => import('../views/Vehicule/Vehicule.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/printtest',
    name: 'PrintTest',
    component: () => import('../views/pdf/vue/print_test.vue')
  },
  {
    path: '/localisation',
    name: 'Localisation',
    component: () => import('../views/Localisation/Localisation.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',

// const router = createRouter({
//   history: createWebHashHistory(),
  base: process.env.BASE_URL,
  routes
})

export default router
